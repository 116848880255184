$lightgrey: #e4e4e4
$lightblue: #7aa7c7
$lightgreen: #7fc77a
$hovergrey: #b1b1b1
$hoverblue: #4683ae
$pillheight: 1.5em

@mixin pillstyle($bgcolor, $fcolor:black)
    height: $pillheight
    color: $fcolor
    background: $bgcolor
    border: 3px solid $bgcolor
    padding: 4px 8px
    white-space: nowrap
    display: flex
    align-items: center
    border-radius: 8px

    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        border-radius: 2vw

html, body
    height: 100%
    width: 100%
    font-family: "Roboto"
    padding: 0
    overflow: hidden
    font-size: 2vh
    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        font-size: 2.5vh

h1
    margin: 0
    display: flex
    align-items: center

    span:first-child
        color: $lightgreen

    span:last-child
        color: $lightblue

h2
    margin: .6em 0 .4em 0

h3
    margin: .4em 0 .4em 0
    color: $lightblue

#content
    height: 100%
    width: auto
    padding: 3vw
    position: relative
    z-index: 0
    overflow-y: scroll

span.pill
    @include pillstyle($lightgrey)

.pill-freq
    color: $lightblue

#entries
    position: sticky
    top: 0px
    z-index: 10
    margin-top: .5em
    background: white

    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        top: 5px

.entry
    width: auto
    display: flex
    flex-direction: row
    flex-wrap: wrap
    column-gap: 5px
    row-gap: 5px
    position: relative
    @include pillstyle($lightgrey)
    height: auto

    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        padding: 8px

    .pills
        gap: 1vh

        .pill
            background: white

    .input
        padding: 0
        border: None
        outline: None
        background: white
        min-width: 10vw
        white-space: normal
        border: 5px solid $lightgrey
        font-size: 1.7em
        margin: -5px
        width: 100%
        height: auto

        @media only screen and (max-device-width: 812px) and (orientation: portrait)
            font-size: 2em
            margin: 0

    .create
        align-items: flex-start
        height: 2em
        padding: 0
        width: 100%

        @media only screen and (max-device-width: 812px) and (orientation: portrait)
            height: 2.5em
            padding: 4px 0

    .create i
        cursor: pointer
        font-size: 2em

    .create:focus .suggestions
        display: block

.pills
    display: none
    width: 100%
    flex-direction: row
    flex-wrap: wrap
    column-gap: 1vh
    row-gap: 1vh
    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        column-gap: 1.5vh
        row-gap: 2vw

.pill
    display: flex
    flex-direction: row
    position: relative
    @include pillstyle($lightgrey)

    .close, .delete
        color: grey
        cursor: pointer

.pill.template
    display: none

#options
    position: absolute
    bottom: 3vw
    right: 3vw
    display: flex
    flex-direction: row
    column-gap: 10px
    justify-content: space-between

    .buttons
        display: flex
        align-items: center
        column-gap: .2em
        font-size: 2em

    .button:last-child
        background: $lightgreen
        border-color: $lightgreen

    .button
        cursor: pointer
        @include pillstyle($lightblue, white)
        height: 50px
        width: 50px
        font-size: 80%
        justify-content: center
        @media only screen and (max-device-width: 812px) and (orientation: portrait)
            height: 12vw
            width: 12vw

#popular
    .pills
        max-height: 46vh
        overflow-y: auto
        align-content: flex-start

#history
    border-top: 10px solid $lightblue

#popular, #history
    display: block

    .pill
        cursor: pointer

    .pill:hover
        @include pillstyle($hovergrey)

    .header-with-buttons
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center

        #toggles
            font-size: 1.5em
            color: $lightblue
            margin: .6em 0 .4em

#info
    padding-bottom: 4em

    img
        border-radius: 2px solid $lightgrey
        width: 600px

        @media only screen and (max-device-width: 812px) and (orientation: portrait)
            width: 100%

#loading-layer
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    background: rgba(white, .8)
    z-index: 100
    display: flex
    align-items: center
    justify-content: center
    display: none
    font-size: 300%

#datetime-picker
    width: 100%
    padding: 8px
    margin-top: .5em
    display: none

    input
        font-size: 1.5em
        border: none
        padding: 0
        margin: 0
        color: $lightgreen

.table
    border-collapse: collapse
    margin-bottom: 1em

.row
    display: table-row
    padding: 15px 0

.cell:first-child
    font-size: 80%

.cell
    display: table-cell
    white-space: nowrap
    text-align: left
    padding: 5px 10px

    @media only screen and (max-device-width: 812px) and (orientation: portrait)
        padding: 9px
